import React from "react"
import Layout from "./../components/layouts/Default"
import Seo from "./../components/layouts/Seo"
import Header from "./../components/common/Header"
import { graphql, useStaticQuery } from "gatsby"
import Card from "./../components/common/Card"
import Reviews from "./../components/Reviews"

export default function Page() {
  const data = useStaticQuery(graphql`
    query Geldanlage {
      header: file(
        relativePath: { eq: "headers/dierda_geldanlage_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      geldanlage: file(
        relativePath: { eq: "headers/dierda_geldanlage-allgemein_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Geldanlage"
        description="Vermögensaufbau ist komplex, aber durch eine intelligente
          Anlagestrategie steuerbar. "
        path="/geldanlage/"
      />
      <Header
        link="#angebote"
        linktext="Zum Angebot"
        image={data.header.childImageSharp.fluid}
      >
        <h1 className="mt-1 text-2xl leading-relaxed sm:text-3xl lg:text-4xl font-extrabold text-gray-900  ">
          <span className="text-blue-500">Geldanlage</span>
        </h1>

        <p className="prose prose-lg text-gray-700 ">
          Vermögensaufbau ist komplex, aber durch eine intelligente
          Anlagestrategie steuerbar. Wir beraten Dich und zeigen Dir die große
          Vielfalt an Möglichkeiten auf – ganz individuell nach Deinen
          Bedürfnissen.
        </p>
      </Header>
      <div className="container mx-auto my-6">
        <div className="relative  pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center" id="angebote">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Unsere Geldanlageangebote
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-700 sm:mt-4">
                Nachfolgend findest Du eine Auswahl unserer
                Geldanlage-Leistungen.
              </p>
            </div>
            <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
              <Card
                image={data.geldanlage.childImageSharp.fluid}
                title="Allgemeine Geldanlage"
                link="/geldanlage/allgemeine-geldanlage/"
                subtitle="Geldanlage"
                sublink="/geldanlage/"
                text="Geldanlage: Wie Du Dein Geld langfristig vermehrst."
              />
            </div>
          </div>
        </div>
        <Reviews />
      </div>
    </Layout>
  )
}
